import {
    Link as ChakraLink,
    Button,
    useDisclosure,
    useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
  } from '@chakra-ui/react'

import {IoSettings, IoExit, IoChevronForward,IoLogoEuro, IoBriefcase, IoAlarm, IoHandLeft, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle} from 'react-icons/io5';


import React, { useEffect, useState } from "react";
import { useTranslation } from 'next-i18next'

const DeleteModal = ({ isOpen, onCancel, onConfirm, headerMessage, bodyMessage}) => {

  const toast = useToast()
  const cancelRef = React.useRef()

  const {t} = useTranslation('common')

  const userNotification = async (title, subTitle, status) => {
    toast({
      title: title,
      description: subTitle,
      status: status,
      duration: 5000,
      isClosable: true,
      position: 'bottom',
    })
  }

  const modalContent = isOpen ? (
   <div>
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancel}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    {headerMessage}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      {bodyMessage}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onCancel}>
                        {t("deleteModalElement.cancel")}
                    </Button>
                    <Button colorScheme='red' onClick={onConfirm} ml={3}>
                        <IoTrash></IoTrash>
                        <p style={{marginLeft: 10}}>
                        {t("deleteModalElement.deletePerm")}
                        </p>
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </div>
  ) : null;
  
  return modalContent
};

export default DeleteModal;