import {
    Link as ChakraLink,
    Text,
    Box,
    Select
} from '@chakra-ui/react'

import React from "react";

import { useTranslation } from 'next-i18next'

const SelectInvoiceType = ({changeValue ,disabled, isInvalid, value, invoiceTypesData}) => {

  const {t} = useTranslation('common')
  return(
        <Box mt="2">
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("selectInvoiceTypeElement.flowType")}</Text>
          <Select
            value={value}
            onChange={(e) =>{ changeValue(`invoiceType`, e.target.value)}}
            disabled={disabled}
            isInvalid={isInvalid}
          >
            <option value="" disabled selected>{t("selectInvoiceTypeElement.pleaseSelect")}</option>
            {invoiceTypesData.map((singleCategory)=>{
              return(
                <option key={singleCategory._id+"edit-inv"} value={singleCategory._id}>{singleCategory.title}</option>
              )
            })}
          </Select>
        </Box>
  )
};

export default SelectInvoiceType;