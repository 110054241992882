import {
    Link as ChakraLink,
    Text,
    GridItem,
    Select
} from '@chakra-ui/react'

import React from "react";


import { useTranslation } from 'next-i18next'

const SelectCurrency = ({changeValue ,disabled, value}) => {
  const {t} = useTranslation('common')
  return(
    <GridItem>
        <Text mt="4" fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("currencyElement.currency")}</Text>
        <Select 
        onChange={(e) =>{ changeValue(`currency`, e.target.value)}}
        value={value}
        disabled={disabled}
        >
        <option value="EUR" >€ (EURO)</option>
        <option value="USD">$ (US DOLLAR)</option>
        <option value="GBP">£ (BRITISH POUND)</option>
        <option value="JPY">¥ (JAPANESE YEN)</option>
        <option value="INR">₹ (INDIAN RUPEE)</option>
        <option value="CAD">$ (CANADIAN DOLLAR)</option>
        <option value="CHF">CHf (SWISS FRANC)</option>
        <option value="DKK">kr (DANISH KRONE)</option>
        </Select>
    </GridItem> 
  )
};

export default SelectCurrency;