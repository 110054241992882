import {
    Link as ChakraLink,
    Text,
    Input,
    Box,
    Badge,
    SimpleGrid,
    Button,
    Select,
    Heading,
    Avatar,
    Grid,
    GridItem,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Center,
    Divider,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Progress,
    Tooltip,
    Alert,
    AlertIcon,
    HStack,
    Spacer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Menu,
    VStack,
    useDisclosure,
    Flex,
    InputGroup,
  } from '@chakra-ui/react'



import Iframe from 'react-iframe'

import CurrencyInput from 'react-currency-input-field';


import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

import {IoPrint,IoGift, IoNotifications,IoRemoveCircle, IoChevronForwardOutline,IoChevronBack,  IoBriefcase, IoOpen, IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle, IoAddCircleOutline} from 'react-icons/io5';

import React, { useEffect, useState, useRef,Component } from "react";
import AllIcons from '../constants/AllIcons';
import SelectCurrency from './SelectCurrency';
import SelectBudget from './SelectBudget';
import ChangeLogElement from './ChangeLogElement';
import InvoiceStatusElement from './InvoiceStatusElement';
import SelectInvoiceType from './SelectInvoiceType';
import TravelExpensesElement from './TravelExpenseElement';
import DeleteModal from './DeleteModal';
import axios from 'axios';


import { useTranslation } from 'next-i18next'




const containerStyle = {
  width: '100%',
  height: '500px',
};

const center = {
  lat: 52.52, // Default coordinates (Berlin, for example)
  lng: 13.405,
};


const InvoiceDetailDrawer = ({
  createInvoiceType,
  setBulkUploadCounterFinished,
  bulkUploadSettings,
  setBulkInsertData,
  bulkInsertData,
  uploadInProgress,
  categoriesData,
  userData,
  setUploadInProgress,
  editInvoiceReadonly,
  previewBackwards, 
  previewForwards, 
  editInvoiceReadonlyReason,
  loadInvoices,
  invoiceTypesData,
  loadBudgetSpending,
  loadSingleInvoices,
  scroller,
  rekeningData,
  setRekeningData,
  invoiceIsOpen,
  session,
  setChangedInvoices,
  invoiceOnClose,
  setSelectedInvoice,
  selectedInvoice,
  userNotification,
  createInvoice,
  setCreateInvoice,
  setIsDuplicateModalOpen,
  setDuplicateCurrentFile,
  setDuplicateUserDecision
}) => {

    const {t} = useTranslation('common')

    const { isOpen: isCloseEditWithoutSave, onOpen: onCloseEditWithoutSaveOpen, onClose: onCloseEditWithoutSaveClose } = useDisclosure()

    const [selectedDay, setSelectedDay] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() +1,
        day: new Date().getUTCDate(),
      });
    const countryListAllowance = [
      {
        name: "Afghanistan",
        small: 20,
        large: 30,
        code: "AF"
      },
      {
        name: "Albania",
        small: 18,
        large: 27,
        code: "AL"
      },
      {
        name: "Algeria",
        small: 20,
        large: 30,
        code: "DZ"
      },
      {
        name: "Andorra",
        small: 33,
        large: 50,
        code: "AD"
      },
      {
        name: "Angola",
        small: 26,
        large: 39,
        code: "AO"
      },
      {
        name: "Argentina",
        small: 32,
        large: 47,
        code: "AR"
      },
      {
        name: "Armenia",
        small: 24,
        large: 36,
        code: "AM"
      },
      {
        name: "Australia - Canberra",
        small: 31,
        large: 46,
        code: "AU-CBR"
      },
      {
        name: "Australia - Sydney",
        small: 36,
        large: 52,
        code: "AU-SYD"
      },
      {
        name: "Australia",
        small: 31,
        large: 46,
        code: "AU"
      },
      {
        name: "Austria",
        small: 33,
        large: 50,
        code: "AT"
      },
      {
        name: "Belgium",
        small: 34,
        large: 51,
        code: "BE"
      },
      {
        name: "Brazil - Rio de Janeiro",
        small: 28,
        large: 41,
        code: "BR-RIO"
      },
      {
        name: "Brazil - Sao Paulo",
        small: 35,
        large: 52,
        code: "BR-SP"
      },
      {
        name: "Brazil",
        small: 24,
        large: 36,
        code: "BR"
      },
      {
        name: "Canada - Ottawa",
        small: 36,
        large: 54,
        code: "CA-OTT"
      },
      {
        name: "Canada - Toronto",
        small: 42,
        large: 63,
        code: "CA-TOR"
      },
      {
        name: "Canada - Vancouver",
        small: 42,
        large: 63,
        code: "CA-VAN"
      },
      {
        name: "Canada",
        small: 31,
        large: 46,
        code: "CA"
      },
      {
        name: "China - Beijing",
        small: 32,
        large: 48,
        code: "CN-PEK"
      },
      {
        name: "China - Shanghai",
        small: 39,
        large: 58,
        code: "CN-SHA"
      },
      {
        name: "China",
        small: 32,
        large: 48,
        code: "CN"
      },
      {
        name: "Denmark",
        small: 33,
        large: 50,
        code: "DK"
      },
      {
        name: "Egypt",
        small: 20,
        large: 30,
        code: "EG"
      },
      {
        name: "Finland",
        small: 36,
        large: 54,
        code: "FI"
      },
      {
        name: "France - Paris",
        small: 39,
        large: 58,
        code: "FR-PAR"
      },
      {
        name: "France",
        small: 36,
        large: 53,
        code: "FR"
      },
      {
        name: "Germany",
        small: 16,
        large: 24,
        code: "DE"
      },
      {
        name: "Greece",
        small: 35,
        large: 52,
        code: "GR-ATH"
      },
      {
        name: "Greece",
        small: 27,
        large: 40,
        code: "GR"
      },
      {
        name: "Hungary",
        small: 23,
        large: 34,
        code: "HU"
      },
      {
        name: "India - Mumbai",
        small: 33,
        large: 50,
        code: "IN-BOM"
      },
      {
        name: "India - New Delhi",
        small: 33,
        large: 50,
        code: "IN-DEL"
      },
      {
        name: "India",
        small: 21,
        large: 32,
        code: "IN"
      },
      {
        name: "Indonesia",
        small: 24,
        large: 36,
        code: "ID"
      },
      {
        name: "Ireland",
        small: 39,
        large: 58,
        code: "IE"
      },
      {
        name: "Israel",
        small: 44,
        large: 66,
        code: "IL"
      },
      {
        name: "Italy - Milan",
        small: 32,
        large: 48,
        code: "IT-MIL"
      },
      {
        name: "Italy - Rome",
        small: 39,
        large: 58,
        code: "IT-ROM"
      },
      {
        name: "Italy",
        small: 29,
        large: 44,
        code: "IT"
      },
      {
        name: "Japan - Tokyo",
        small: 52,
        large: 78,
        code: "JP-TYO"
      },
      {
        name: "Japan",
        small: 38,
        large: 57,
        code: "JP"
      },
      {
        name: "Kenya",
        small: 32,
        large: 48,
        code: "KE"
      },
      {
        name: "Malaysia",
        small: 24,
        large: 36,
        code: "MY"
      },
      {
        name: "Mexico",
        small: 31,
        large: 46,
        code: "MX"
      },
      {
        name: "Netherlands",
        small: 32,
        large: 47,
        code: "NL"
      },
      {
        name: "New Zealand",
        small: 39,
        large: 58,
        code: "NZ"
      },
      {
        name: "Nigeria",
        small: 31,
        large: 46,
        code: "NG"
      },
      {
        name: "Norway",
        small: 50,
        large: 75,
        code: "NO"
      },
      {
        name: "Poland - Breslau",
        small: 27,
        large: 33,
        code: "PL-WRO"
      },
      {
        name: "Poland - Danzig",
        small: 27,
        large: 33,
        code: "PL-GDN"
      },
      {
        name: "Poland - Krakau",
        small: 27,
        large: 33,
        code: "PL-KRK"
      },
      {
        name: "Poland - Warsaw",
        small: 29,
        large: 34,
        code: "PL-WAW"
      },
      {
        name: "Poland",
        small: 20,
        large: 29,
        code: "PL"
      },
      {
        name: "Portugal - Lisbon",
        small: 32,
        large: 47,
        code: "PT-LIS"
      },
      {
        name: "Portugal",
        small: 29,
        large: 44,
        code: "PT"
      },
      {
        name: "Russia - Moscow",
        small: 40,
        large: 60,
        code: "RU-MOW"
      },
      {
        name: "Russia",
        small: 30,
        large: 45,
        code: "RU"
      },
      {
        name: "Saudi Arabia",
        small: 28,
        large: 42,
        code: "SA"
      },
      {
        name: "South Africa - Johannesburg",
        small: 31,
        large: 46,
        code: "ZA-JNB"
      },
      {
        name: "South Africa - Cape Town",
        small: 31,
        large: 46,
        code: "ZA-CPT"
      },
      {
        name: "South Africa",
        small: 30,
        large: 45,
        code: "ZA"
      },
      {
        name: "Spain - Madrid",
        small: 44,
        large: 66,
        code: "ES-MAD"
      },
      {
        name: "Spain - Barcelona",
        small: 44,
        large: 66,
        code: "ES-BCN"
      },
      {
        name: "Spain",
        small: 34,
        large: 51,
        code: "ES"
      },
      {
        name: "Sweden",
        small: 35,
        large: 52,
        code: "SE"
      },
      {
        name: "Switzerland - Geneva",
        small: 43,
        large: 65,
        code: "CH-GVA"
      },
      {
        name: "Switzerland",
        small: 33,
        large: 50,
        code: "CH"
      },
      {
        name: "Turkey - Istanbul",
        small: 29,
        large: 44,
        code: "TR-IST"
      },
      {
        name: "Turkey",
        small: 24,
        large: 36,
        code: "TR"
      },
      {
        name: "United Arab Emirates",
        small: 32,
        large: 48,
        code: "AE"
      },
      {
        name: "UK - London",
        small: 44,
        large: 66,
        code: "UK-LON"
      },
      {
        name: "UK",
        small: 35,
        large: 52,
        code: "UK"
      },
      {
        name: "USA - New York City",
        small: 44,
        large: 66,
        code: "US-NYC"
      },
      {
        name: "USA - Washington, D.C.",
        small: 44,
        large: 66,
        code: "US-WDC"
      },
      {
        name: "USA - Atlanta",
        small: 52,
        large: 77,
        code: "US-ATL"
      },
      {
        name: "USA - Boston",
        small: 42,
        large: 63,
        code: "US-BOS"
      },
      {
        name: "USA - Chicago",
        small: 44,
        large: 66,
        code: "US-CHI"
      },
      {
        name: "USA - Houston",
        small: 41,
        large: 62,
        code: "US-HOU"
      },
      {
        name: "USA - Los Angeles",
        small: 43,
        large: 64,
        code: "US-LAX"
      },
      {
        name: "USA - Miami",
        small: 44,
        large: 65,
        code: "US-MIA"
      },
      {
        name: "USA - San Francisco",
        small: 40,
        large: 59,
        code: "US-SFO"
      },
      {
        name: "USA",
        small: 40,
        large: 60,
        code: "US"
      }
    ];
    


    const emptyInvoice = {
        _id: undefined,
        status: "",
        invoiceKind: "invoice",
        category:"",
        uuid:"",
        shop:"",
        total:"0",
        tax:"0",
        totalNet:"0",
        mwst:"0",
        date:new Date().toUTCString(),
        items:[],
        scanAttached:"",
        scanAttachedMD5:"",
        location:{},
        paymentType:"",
        invoiceText:"",
        author: "",
        authorData:[{name:""}],
        currency: "EUR",
        changelog: [],
        travelExpense:{},
        taxPositions: [
          {
            taxRate: "19",
            value: "0",
            valueNet: "0"
          }
        ],
        invoiceType: undefined,
        invoiceStatus: undefined
      };

      const emptyTravelExpense = {
          locationFrom:"",
          locationTo:"",
          transportation:"train",
          transportationDistance:"0",
          breakfasts:0,
          lunches:0,
          dinners:0,
          startDate: null,
          endDate:null,
          country: "DE",
          allowanceSmall: "14",
          allowanceBig: "28",
          meals:[]
      };

  const [newInvoice, setNewInvoice] = useState(emptyInvoice);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [editInvoiceModified, setEditInvoiceModified] = useState(false);

  const [saveInvoiceLoading, setSaveInvoiceLoading] = useState(false);
  const [editInvoiceErrorArray, setEditInvoiceErrorArray] = useState([]);

  const api_url = process.env.NEXT_PUBLIC_API_URL
  const fileInputRef = useRef(null);


//MAP FOR TRAVEL EXPENSE
  const [startLocation, setStartLocation] = useState(null);
  const [endLocation, setEndLocation] = useState(null);
  const [map, setMap] = useState(null);

  
  React.useEffect(() => {
    let currentDate = new Date().toUTCString();
    let emptyInvoiceTemp = emptyInvoice;
    if(createInvoiceType == "travel-expense"){
      emptyInvoiceTemp.invoiceKind = "travel-expense";
      emptyInvoiceTemp.travelExpense = emptyTravelExpense;
      console.log("UPADET NEW INVOICE")
    }else{
      delete emptyInvoiceTemp.travelExpense;
    }
    setNewInvoice(emptyInvoiceTemp)

    setSelectedDay({
      year: new Date(currentDate).getFullYear(),
      month: new Date(currentDate).getMonth()+1,
      day: new Date(currentDate).getUTCDate(),
    })
  }, [invoiceIsOpen])

  React.useEffect(() => {
        if(bulkInsertData.length != 0){
            bulkInsert(bulkInsertData)
        }
  }, [bulkInsertData])

    const renderCustomInput = ({ ref }) => (
        <Input
          readOnly
          ref={ref} // necessary
          style={{
            width: "100%"
          }}
          placeholder="I'm a custom input"
          value={selectedDay ? `${selectedDay.day}.${selectedDay.month}.${selectedDay.year}` : ''}
        />
    )

    const closeInvoice = async ()=>{
        setCreateInvoice(true);
        setEditInvoiceErrorArray([]);
        setEditInvoiceModified(false);
        invoiceOnClose()
    }

  const deleteCurrentInvoice = async()=>{
    deleteInvoice(rekeningData[selectedInvoice]._id, false);
    setCreateInvoice(true);
    setSelectedInvoice(0);
    invoiceOnClose();
    setIsDeleteOpen(false);
    //calculateMonthlyCost()
    //setRekeningData([...rekeningDataTemp])
  }

  const deleteInvoice = async (id, bulkDelete) =>{

    const fetchData = await fetch(`${api_url}/invoices/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
      },
    })
    let rekeningDataTemp = rekeningData.filter(e => e._id != id);
    if(!bulkDelete){
      setRekeningData(rekeningDataTemp);
    }
    setEditInvoiceModified(false)
    userNotification(t("invoiceDetails.deleteSuccessfull"), t("invoiceDetails.invDeletedSuccess"), "success");
    setChangedInvoices([])
  }
  const checkInvoiceDuplicate = async (scanAttachedMD5) =>{

    const fetchData = await fetch(`${api_url}/invoices/checkDuplicate?scanAttachedMD5=${scanAttachedMD5}`, {
      credentials: "include",
      method: "GET",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      }
    })

    const fetchDataJson = await fetchData.json();

    console.log("RESPONSE: ",fetchDataJson);

    if("statusCode" in fetchDataJson && fetchDataJson["message"] == "Internal server error"){
      return []
    }else{
      return fetchDataJson;
    }
  }

  const createNewInvoice = async (data, bulkUpload = false) =>{
    let body = data;


    if(data == undefined){
      return;
    }
    
    delete body.authorData;


    const fetchData = await fetch(`${api_url}/invoices`, {
      credentials: "include",
      method: "POST",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    const fetchDataJson = await fetchData.json();

    if("statusCode" in fetchDataJson && fetchDataJson["message"] == "Internal server error"){
      userNotification(t("invoiceDetails.errorCreateInv"), "error")
    }else if("statusCode" in fetchDataJson){
      console.log(fetchDataJson);
      userNotification("Creation Error", fetchDataJson["message"][0], "error");
      let errorTypes = []
      fetchDataJson["message"].map((singleError)=>{
        if(singleError == "invoiceType should not be empty"){
          errorTypes.push("invoiceType");
        }else if(singleError == "shop should not be empty"){
          errorTypes.push("shop");
        }else if(singleError == "taxPositions.0.value should not be empty" || singleError == "taxPositions.1.value should not be empty" || singleError == "taxPositions.2.value should not be empty" || singleError == "taxPositions.3.value should not be empty"){
          errorTypes.push("amount");
        }else if(singleError == "taxPositions.0.valueNet should not be empty" || singleError == "taxPositions.1.valueNet should not be empty" || singleError == "taxPositions.2.valueNet should not be empty" || singleError == "taxPositions.3.valueNet should not be empty"){
          errorTypes.push("amountNet");
        }else if(singleError == "taxPositions.0.taxRate should not be empty" || singleError == "taxPositions.1.taxRate should not be empty" || singleError == "taxPositions.2.taxRate should not be empty" || singleError == "taxPositions.3.taxRate should not be empty"){
          errorTypes.push("taxRate");
        }else if(singleError == "category should not be empty"){
          errorTypes.push("category");
        }else if(singleError == "travelExpense.locationFrom should not be empty"){
          errorTypes.push("locationFrom");
        }else if(singleError == "travelExpense.locationTo should not be empty"){
          errorTypes.push("locationTo");
        }else if(singleError == "travelExpense.transportationDistance should not be empty"){
          errorTypes.push("transportationDistance");
        }else if(singleError == "travelExpense.startDate must be a Date instance"){
          errorTypes.push("startDate");
        }else if(singleError == "travelExpense.endDate must be a Date instance"){
          errorTypes.push("endDate");
        }
      });
      setEditInvoiceErrorArray(errorTypes)
    }else{
      userNotification(t("invoiceDetails.createSucces"),t("invoiceDetails.invSuccessCreate"),"success");
      invoiceOnClose()
      setEditInvoiceErrorArray([]);
      await setNewInvoice(emptyInvoice);
      await setCreateInvoice(false);
    

      await setRekeningData([]);
      await loadInvoices()
      if(!bulkUpload  && "statusCode" in fetchDataJson == false){
        setChangedInvoices([fetchDataJson._id]);
        setTimeout(()=>{
          scroller.scrollTo(fetchDataJson._id, {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: -100, // Scrolls to element + 50 pixels down the page
          })
        },200)
      }
    }
    return fetchDataJson;
  }


  const saveInvoiceData = async(openInvoiceData)=>{
    setEditInvoiceModified(true);
    if(createInvoice == true){
      setNewInvoice({...openInvoiceData})
    }else{

      const rekeningDataTemp = rekeningData;
      rekeningDataTemp[selectedInvoice] = openInvoiceData;
      setRekeningData([...rekeningDataTemp]);
    }
  }

  const updateInvoice = async (data) =>{
    let body = data

    if(data == undefined){
      return;
    }

    const fetchData = await fetch(`${api_url}/invoices/${data._id}`, {
      method: "PATCH",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const fetchDataJson = await fetchData.json();
    if("statusCode" in fetchDataJson){
      userNotification(t("invoiceDetails.updateError"), fetchDataJson["message"][0], "error");

      let errorTypes = []
      fetchDataJson["message"].map((singleError)=>{
        if(singleError == "invoiceType should not be empty"){
          errorTypes.push("invoiceType");
        }else if(singleError == "shop should not be empty"){
          errorTypes.push("shop");
        }else if(singleError == "taxPositions.0.value should not be empty" || singleError == "taxPositions.1.value should not be empty" || singleError == "taxPositions.2.value should not be empty" || singleError == "taxPositions.3.value should not be empty"){
          errorTypes.push("amount");
        }else if(singleError == "taxPositions.0.valueNet should not be empty" || singleError == "taxPositions.1.valueNet should not be empty" || singleError == "taxPositions.2.valueNet should not be empty" || singleError == "taxPositions.3.valueNet should not be empty"){
          errorTypes.push("amountNet");
        }else if(singleError == "taxPositions.0.taxRate should not be empty" || singleError == "taxPositions.1.taxRate should not be empty" || singleError == "taxPositions.2.taxRate should not be empty" || singleError == "taxPositions.3.taxRate should not be empty"){
          errorTypes.push("taxRate");
        }else if(singleError == "category should not be empty"){
          errorTypes.push("category");
        }
      });
      setEditInvoiceErrorArray(errorTypes)
      return false;
    }else{
      userNotification(t("invoiceDetails.updateSuccess"), t("invoiceDetails.invUpdateSuccessfully"), "success");
      setChangedInvoices([fetchDataJson["_id"]]);
      closeInvoice()

      setEditInvoiceModified(false);
      setEditInvoiceErrorArray([]);

      await loadSingleInvoices(data._id)
      return true;
    }
    
  }
  
  const onChangeTextOpenInvoice = (key, val) => {
    
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    if(key == "total"){
      val = val.replace(",",".");
    }
    if(key == "mwst"){
      if(val <= 0){
        val = 0;
      }if(val >= 100){
        val = 100;
      }
    }
    openInvoiceData[key] = val;

    if(key == "total" || key == "mwst"){
      let vat;
      let total = Number(openInvoiceData.total);
      vat = total * (1 - parseInt(openInvoiceData.mwst) / 100);
      vat = vat.toFixed(2);
      openInvoiceData.tax = vat;
    }
    if(key == "category"){
      loadBudgetSpending(val);
    }
    
    if(key == "invoiceType"){
      
      let invTypeTemp = invoiceTypesData.find(e=> e._id == val);
      
      openInvoiceData["invoiceStatus"] = invTypeTemp.steps[0]._id;
    }

    //SAVE
    saveInvoiceData(openInvoiceData);
  }


  const editInvoiceStatusUpdate = async (update) =>{
    let invoiceTypeStatusIndex,
    invoiceTypeTemp;

    invoiceTypeTemp = invoiceTypesData.find(e => e._id == editInvoice.invoiceType);

    if(invoiceTypeTemp != undefined){
      invoiceTypeStatusIndex = invoiceTypeTemp.steps.findIndex(e => e._id == editInvoice.invoiceStatus);
    }
    if(invoiceTypeTemp == undefined || invoiceTypeStatusIndex == undefined){
      return;
    }
    if(update == "approve" && invoiceTypeStatusIndex < invoiceTypeTemp.steps.length - 1){
      onChangeTextOpenInvoice("invoiceStatus" ,invoiceTypeTemp.steps[invoiceTypeStatusIndex+1]._id);
    }else if(update == "reject" && invoiceTypeStatusIndex > 0 ){
      onChangeTextOpenInvoice("invoiceStatus" ,invoiceTypeTemp.steps[invoiceTypeStatusIndex -1]._id);
    }
    saveInvoice()
  }

  const calculateTravelExpenseCost = async (body) => {
    let travelExpenseTemp = editInvoice.travelExpense;
   
    if(
      travelExpenseTemp["title"] == "" ||
      travelExpenseTemp["allowanceBig"] == "" ||
      travelExpenseTemp["allowanceSmall"] == "" ||
      travelExpenseTemp["breakfasts"] == "" ||
      travelExpenseTemp["lunches"] == "" ||
      travelExpenseTemp["dinners"] == "" ||
      travelExpenseTemp["startDate"] == "" ||
      travelExpenseTemp["endDate"] == ""||
      travelExpenseTemp["description"] == ""||
      travelExpenseTemp["locationTo"] == ""||
      travelExpenseTemp["transportationDistance"] == ""
    ){
      return;
    }
    const fetchData = await fetch(`${api_url}/travelExpense/calculate`, {
      credentials: "include",
      method: "POST",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    const fetchDataJson = await fetchData.json();

    if("statusCode" in fetchDataJson){
      //userNotification("Creation Error", fetchDataJson["message"][0], "error");
    }else{
      travelExpenseTemp["compensation"] = fetchDataJson.cost;
      let tempInvoiceData = editInvoice;
      tempInvoiceData.travelExpense = travelExpenseTemp;
      saveInvoiceData(tempInvoiceData)

      //userNotification("New Travel Expense Created","Your new travel expense has been successfully created","success");
      //onTravelExpenseClose()
    }
  }

  const changeTravelExpenseLocation = async(key, value)=>{
    console.log("UPDATE TRAVEL", key, value);

    let location = value.label;
    let locationPlaceId = value.value.place_id;
    await onChangeTextTravelExpense(key, location);
    await onChangeTextTravelExpense(key+"PlaceId", locationPlaceId);
    
    if(key == "locationTo"){
      let country = value.value.terms;
      await onChangeTextTravelExpense("country", country);
    }

    let locationFrom = editInvoice.travelExpense["locationFrom"],
    locationTo = editInvoice.travelExpense["locationTo"];
    console.log("UPDATE LOCATION.")
    if(locationFrom != "" && locationTo != ""){
      console.log("Check Distance");
      let distance = await updateDistance(locationFrom, locationTo);
      onChangeTextTravelExpense("transportationDistance", distance);
    }

    /*

    MAP Visualization for travel not working

    const { lat, lng } = value.value.geometry.location;
    const selectedLocation = { lat: lat(), lng: lng() };

    if (key === "locationFrom") {
      setStartLocation(selectedLocation);
    } else if (key === "locationTo") {
      setEndLocation(selectedLocation);
    }*/
  }
  /*
  useEffect(() => {
    if (map && startLocation && endLocation) {
      const bounds = {
        north: Math.max(startLocation.lat, endLocation.lat),
        south: Math.min(startLocation.lat, endLocation.lat),
        east: Math.max(startLocation.lng, endLocation.lng),
        west: Math.min(startLocation.lng, endLocation.lng),
      };
      map.fitBounds(bounds);
    }
  }, [startLocation, endLocation, map]);*/

  const onChangeTextTravelExpense = async (key, value) =>{

      let invoiceDataTemp = editInvoice;
      

      if(key == "country"){
        let country = value.pop().value;
        let region = value.length != 0 && value.pop().value;
        let city = value.length != 0 && value.pop().value;


        let countryAllowance = undefined;        
        
        //Check for country + region
        countryAllowance = countryListAllowance.find(e => e.name == country +" - "+region);
        
        //Check for country + city
        if(countryAllowance == undefined){
          countryAllowance = countryListAllowance.find(e => e.name == country +" - "+city);
        }

        //Check just for country
        if(countryAllowance == undefined){
          countryAllowance = countryListAllowance.find(e => e.name == country);
        }

        if(countryAllowance == undefined){
          invoiceDataTemp.travelExpense["allowanceSmall"] = "";
          invoiceDataTemp.travelExpense["allowanceBig"] = "";
          invoiceDataTemp.travelExpense[key] = value;
        }else{
          invoiceDataTemp.travelExpense["allowanceSmall"] = countryAllowance.small.toString();
          invoiceDataTemp.travelExpense["allowanceBig"] = countryAllowance.large.toString();
          invoiceDataTemp.travelExpense[key] = countryAllowance.code;
        }
      }else{

        invoiceDataTemp.travelExpense[key] = value;
      }
      try {
        updateMeals(0, "breakfast", editInvoice.travelExpense["meals"][0]["breakfast"]) 
      } catch (error) {
        console.log(error)
      }

      saveInvoiceData(invoiceDataTemp)
      //calculateTravelExpenseCost(invoiceDataTemp.travelExpense)
  }

  const updateDistance = async(origin, destination)=>{
    const apiKey = 'AIzaSyAAmYYPOLxV94MyxnuATvQj93Y0Lik0FwE';

    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const result = response.data;
      const distanceInMeters = (result.rows[0].elements[0].distance.value / 1000).toFixed(0);
      return distanceInMeters
    } catch (error) {
      return 0
      console.error('Error fetching data: ', error);
    }
  }
  
  const updateMeals = async (index, mealType, value)=>{
    
    let invoiceDataTemp = editInvoice;
    
    invoiceDataTemp.travelExpense["meals"][index][mealType] = value;

    let totalAllowance = 0,
    allowanceBig = invoiceDataTemp.travelExpense["allowanceBig"],
    allowanceSmall = invoiceDataTemp.travelExpense["allowanceSmall"];

    invoiceDataTemp.travelExpense["meals"].map((singleMeal, dayIndex)=>{
      
      let deductionBreakfast = parseFloat(allowanceBig)*0.2;
      let deductionLunch = parseFloat(allowanceBig)*0.4;
      let deductionDinner = parseFloat(allowanceBig)*0.4;
      let allowance = parseFloat(allowanceBig)
                
      if(dayIndex == 0 || dayIndex == invoiceDataTemp.travelExpense["meals"].length - 1){
        allowance = parseFloat(allowanceSmall)
      }

      if(singleMeal.breakfast == false){
        deductionBreakfast = 0;
      }
      if(singleMeal.lunch == false){
        deductionLunch = 0;
      }
      if(singleMeal.dinner == false){
        deductionDinner = 0;
      }

      totalAllowance += Math.max(allowance - deductionBreakfast - deductionLunch - deductionDinner, 0);
    })
    console.log("TOTAL:", totalAllowance.toFixed(2));
    console.log("TOTAL:",invoiceDataTemp);
    saveInvoiceData(invoiceDataTemp)
    await onChangeTotalOpenInvoice("taxRate", "0", 0);
    await onChangeTotalOpenInvoice("value", totalAllowance, 0);

  }

  const onChangeTotalOpenInvoice = (key, val, ind)=>{


    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }


    let tax = parseFloat("0.0"),
    total = parseFloat("0.0"),
    totalNet = parseFloat("0.0");
    

    console.log("VAL: ", val)
    openInvoiceData.taxPositions[ind][key] = val;

    let indValue = openInvoiceData.taxPositions[ind]["value"],
    indValueNet = openInvoiceData.taxPositions[ind]["valueNet"],
    indTaxRate = openInvoiceData.taxPositions[ind]["taxRate"];


    openInvoiceData.taxPositions.map((singlePosition, i)=>{

      if(key == "value"){
        if(i == ind && indValue != "" && indTaxRate != ""){
          
          openInvoiceData.taxPositions[ind]["valueNet"] = (parseFloat(indValue) / (1 + parseInt(indTaxRate) / 100)).toFixed(2).toString();
      
          /*openInvoiceData.taxPositions[ind]["value"] = indValue;
          openInvoiceData.taxPositions[ind]["taxRate"] = (1 - (parseFloat(indValue) / parseFloat(indValueNet)) * 100).toFixed(0).toString();
          */
        }
      }

      if(key == "valueNet"){
        if(i == ind){
          indValueNet = val;
        }
      }

      if(key == "taxRate"){
        if(val <= 0){
          val = 0;
        }if(val >= 100){
          val = 100;
        }
        if(i == ind){
          indTaxRate = val;
        }
      }
      
      total += Number(singlePosition.value);
      /*totalNet += parseFloat(singlePosition.value) / (1 + parseInt(singlePosition.taxRate) / 100);
      tax += parseFloat(singlePosition.value) - (parseFloat(singlePosition.value) / (1 + parseInt(singlePosition.taxRate) / 100));
      */
      totalNet += parseFloat(singlePosition.valueNet);
      tax += (parseFloat(singlePosition.value) - parseFloat(singlePosition.valueNet));
    })

    

    openInvoiceData.total = total.toFixed(2).toString();
    openInvoiceData.tax = tax.toFixed(2).toString();
    openInvoiceData.totalNet = totalNet.toFixed(2).toString();
    //SAVE
    saveInvoiceData(openInvoiceData);
  }
  
  const editInvoiceAddTaxRate = async()=>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    
    if(openInvoiceData.taxPositions == undefined){
      openInvoiceData.taxPositions = [];
      let tempDataPosition = {
        taxRate: openInvoiceData.mwst,
        value: openInvoiceData.total,
        valueNet: openInvoiceData.totalNet
      }
      openInvoiceData.taxPositions.push(tempDataPosition);
    }

    openInvoiceData.taxPositions.push({taxRate: "0", value: "", valueNet: ""})

    saveInvoiceData(openInvoiceData);
  }
  const editInvoiceRemoveTaxRate = async (index)=>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    
    openInvoiceData.taxPositions.splice(index, 1);

    saveInvoiceData(openInvoiceData);

  }

  const removeImageFromInvoice = async () =>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    
    openInvoiceData.scanAttached = "";
    openInvoiceData.scanAttachedMD5 = "";
    
    saveInvoiceData(openInvoiceData);


  }

  const changeInvoiceDate = async(newDate)=>{
    setSelectedDay(newDate);
    const invoiceDate = new Date(newDate.year, newDate.month -1, newDate.day);
    invoiceDate.setHours(12);
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    openInvoiceData.date = invoiceDate.toUTCString();
    saveInvoiceData(openInvoiceData);
  }
  const refund = async (status) =>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    openInvoiceData.status = status;

    saveInvoiceData(openInvoiceData);
  }


  const saveInvoice = async()=>{
    setSaveInvoiceLoading(true);
    console.log("NEW INVOICE Data:", newInvoice)
    let openInvoiceData = newInvoice;
    if(createInvoice == false && !editInvoiceReadonly){
      openInvoiceData = rekeningData[selectedInvoice];
      await updateInvoice(openInvoiceData)
    }else if(createInvoice == true){
      await createNewInvoice(openInvoiceData)
    }else{
      userNotification("Saving not possible",editInvoiceReadonlyReason,"error");
    }
    //setRekeningDataOriginal([...rekeningData])

    setSaveInvoiceLoading(false)
  }

  const removeProduct = async(index)=>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    openInvoiceData.items.splice(index, 1);
    saveInvoiceData(openInvoiceData);
  }



  //Bulk Upload:

  const uploadFile = async(data, returnOnlyData = false, aiDisabled = false) =>{
    
    console.log("AI DISABLED");
    if(returnOnlyData == false){
      setUploadInProgress(true)
    }
    var formData = new FormData();
    formData.append('file', data)
    const fetchData = await fetch(`${api_url}/files/upload/?disableAi=${aiDisabled}`, {
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
      },
      credentials: "include",
      method: "Post",
      body: formData
    })
    const response = await fetchData.json();
    console.log(response);
    if(response.statusCode == 400 || response.statusCode == 500){
      userNotification(t("invoiceDetails.processingError"), t("invoiceDetails.processingErrorDetails"), "error")
      setUploadInProgress(false);
      return;
    }
    if(returnOnlyData == true){
      return response;
    }
    if("data" in response){
      let invoiceData = rekeningData[selectedInvoice];
      invoiceData.scanAttached = response.imageUrl;
      saveInvoiceData(invoiceData);
    }else{
      onChangeTextOpenInvoice("scanAttached",response.imageUrl);
      onChangeTextOpenInvoice("scanAttachedMD5",response.scanAttachedMD5);
    }
    setUploadInProgress(false)
    
  }

  const bulkInsert = async (acceptedFiles) => {
    let counter = 0;
    let newInvoiceIds = [];
    setBulkUploadCounterFinished(0);
    for (const file of acceptedFiles) {
        console.log("FILE:", file);
        let uploadData = await uploadFile(file, true);
        console.log("UPLOAD DATA:", uploadData)
        if (uploadData != undefined && "data" in uploadData) {
            console.log("Upload Data:", uploadData);
            let checkDuplicate = await checkInvoiceDuplicate(uploadData.scanAttachedMD5);
            
            console.log("DUPLICATE CHEHCK", checkDuplicate);

            if (checkDuplicate != undefined && checkDuplicate.length != 0) {
                console.log("DUPLICATE", checkDuplicate)
                // Show the modal and wait for the user's decision
                setDuplicateCurrentFile(file);
                setIsDuplicateModalOpen(true);

                // Wait for user's decision
                const decision = await new Promise((resolve) => {
                    const handleDecision = (decision) => {
                        setIsDuplicateModalOpen(false);
                        resolve(decision);
                    };
                    setDuplicateUserDecision(() => handleDecision);
                });

                if (decision === 'skip') {
                    counter++;
                    setBulkUploadCounterFinished(counter);
                    continue;
                }
            }
            
            uploadData.data.category = bulkUploadSettings.category;
            uploadData.data.invoiceType = bulkUploadSettings.invoiceType;
            uploadData.data.invoiceStatus = invoiceTypesData.find(e => e._id == bulkUploadSettings.invoiceType).steps[0]._id;
            
            let creatInvoice = await createNewInvoice(uploadData.data, true);

            newInvoiceIds.push(creatInvoice["_id"]);
            counter++;
            setBulkUploadCounterFinished(counter);
        } else {
            counter++;
            setBulkUploadCounterFinished(counter);
            userNotification(t("invoiceDetails.uploadError"), t("invoiceDetails.uploadErrorDetails"), "error");
        }
    }
    setChangedInvoices(newInvoiceIds);
    setBulkInsertData([]);
  }


/*
  const bulkInsert = async (acceptedFiles) =>{
    let counter = 0;
    let newInvoiceIds = []
    setBulkUploadCounterFinished(0);
    for (const file of acceptedFiles) {
      console.log("FILE:", file);
        let uploadData = await uploadFile(file, true);
        if("data" in uploadData){
          console.log("Upload DAta:", uploadData);
          let checkDuplicate = await checkInvoiceDuplicate(uploadData.scanAttachedMD5);
          
          if(checkDuplicate != undefined && checkDuplicate.length != 0){
            //show 2 buttons in Frontend and wait fpr decision
          }
          
          uploadData.data.category = bulkUploadSettings.category;
          uploadData.data.invoiceType = bulkUploadSettings.invoiceType;
          uploadData.data.invoiceStatus = invoiceTypesData.find(e => e._id == bulkUploadSettings.invoiceType).steps[0]._id;
          
          let creatInvoice = await createNewInvoice(uploadData.data, true);

          newInvoiceIds.push(creatInvoice["_id"])
          counter = counter + 1;
          setBulkUploadCounterFinished(counter);
        }else{
          counter = counter + 1;
          setBulkUploadCounterFinished(counter);
          userNotification("Upload Error","One document could not be imported", "error")
        }
    }
    setChangedInvoices(newInvoiceIds);
    setBulkInsertData([])
  }*/

  const printTravelExpense = (id)=>{
    window.open("/travelExpensePrint/"+id, "_blank").focus()
  }


  let invoiceTypeNextStepAllowed = false;
  let invoiceTypeBackStepAllowed = false;

    //Data for drawer
    let editInvoice = newInvoice;
    if((createInvoice == true || rekeningData.length == 0)){
        editInvoice = newInvoice;
    }else{
        editInvoice = rekeningData[selectedInvoice];
    }

    try {
        const invoiceTypeTemp = invoiceTypesData.find(e => e._id == editInvoice.invoiceType);
        if(invoiceTypeTemp != undefined){
          const invoiceTypeStatus = invoiceTypeTemp.steps.find(e => e._id == editInvoice.invoiceStatus);
          const invoiceTypeStatusIndex = invoiceTypeTemp.steps.findIndex(e => e._id == editInvoice.invoiceStatus);
    
          let invoiceTypeStepApproval = invoiceTypeStatus.approval.some(item => session.user_data.groups.includes(item));
          invoiceTypeNextStepAllowed = (invoiceTypeStepApproval && invoiceTypeStatusIndex < invoiceTypeTemp.steps.length -1)
          invoiceTypeBackStepAllowed = (invoiceTypeStepApproval && invoiceTypeStatusIndex > 0);
        }else{
          invoiceTypeNextStepAllowed = true;
        }
      } catch (error) {
        console.log(error)
      }

      let currencyInputConfig = {"locale":"de","currency":editInvoice.currency};
    
  const modalContent = invoiceIsOpen ? (
    <div>
    <Drawer
    isOpen={invoiceIsOpen}
    placement='right'
    size="lg"
    onClose={()=>{
      console.log("editInvoiceModified",editInvoiceModified)
      console.log("invoiceTypeNextStepAllowed",invoiceTypeNextStepAllowed)
      console.log("newInvoice",newInvoice)
      if(editInvoiceModified == true && invoiceTypeNextStepAllowed && !createInvoice){
        onCloseEditWithoutSaveOpen()
      }else{
        closeInvoice()
      }
    }}
  >
    <DrawerOverlay style={{zIndex: 10}} />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader  style={{borderBottom: "1px solid #e2e8f0"}}>
        <Grid templateColumns='repeat(16, 1fr)' gap={3}>
          <GridItem>
            <HStack spacing='5px'> 
            {( "authorData" in editInvoice && editInvoice.authorData.length != 0 && ("profilePicture" in editInvoice.authorData[0] == false || editInvoice.authorData[0]["profilePicture"] == "")) &&
              <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' name={editInvoice["authorData"][0]["username"]} />
            }
            {( "authorData" in editInvoice && editInvoice.authorData.length != 0 && "profilePicture" in editInvoice.authorData[0] && editInvoice.authorData[0]["profilePicture"] != "") &&
              <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' src={String(editInvoice["authorData"]?.[0]?.["profilePicture"] || "")} />
            }

            {("authorData" in editInvoice && editInvoice.authorData.length == 0) &&
              <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' name={""} />
            }
              <div className='rekening-invoice-icon'>
                <IoBriefcase fontSize={"35px"}></IoBriefcase>
              </div>
            </HStack>
          </GridItem>
          <GridItem colSpan={8}>
            <Heading  as='h4' size='md'>
              {editInvoice.shop}
            </Heading>
            <Box display='flex' alignItems='baseline'>
              <Box
                color='gray.500'
                fontWeight='semibold'
                letterSpacing='wide'
                ml='0'
                fontSize="initial"
              >
                {new Date(editInvoice.date).toLocaleDateString()}
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={6}>
            <Heading  as='h4' textAlign="right" size='md'>
              {editInvoice.total} {editInvoice.currency}
            </Heading>
            <Box display='flex' alignItems='baseline'>
              <Box
                color='gray.500'
                fontWeight='semibold'
                letterSpacing='wide'
                ml='0'
                fontSize="initial"
                textAlign="right"
                width="100%"
              >
              {editInvoice.totalNet} ({editInvoice.tax}) {editInvoice.currency}
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </DrawerHeader>

      <DrawerBody >
        {invoiceTypeNextStepAllowed == false && createInvoice == false &&
        <Alert mt="5" status='warning'>
          <AlertIcon />
          {"You have no rights to edit this invoice in this status."}
        </Alert>
        }
        <Grid mt="6" mb="2" templateColumns='repeat(8, 1fr)' gap={3}>
          <GridItem colSpan={6}>
              {editInvoice.invoiceKind == "travel-expense" &&
              <Heading as="h3" size={"md"}>
                {t("invoiceDetails.travelExpense")}
              </Heading>
              }
              {editInvoice.invoiceKind == "invoice" || editInvoice.invoiceKind == undefined &&
              <Heading as="h3" size={"md"}>
                {t("invoiceDetails.invoice")}
              </Heading>
              }
          </GridItem>
          <GridItem colSpan={2}>
            {(createInvoice == false && invoiceTypeNextStepAllowed == true) &&
            <Button size={"sm"} float="right" onClick={()=>setIsDeleteOpen(true)}>
              <IoTrash fontSize={"20px"} style={{marginRight: "5px"}}/>
              {editInvoice.invoiceKind == "invoice" && <p>{t("invoiceDetails.deleteInvoice")}</p> }
              {editInvoice.invoiceKind == "travel-expense" && <p>{t("invoiceDetails.deleteTravelExpense")}</p> } 
            </Button>
            }
            <DeleteModal 
              isOpen={isDeleteOpen}
              onCancel={()=>setIsDeleteOpen(false)}
              onConfirm={deleteCurrentInvoice}
              headerMessage={t("invoiceDetails.deleteInvoice")}
              bodyMessage={t("invoiceDetails.sureToDelete")}
            ></DeleteModal>
          </GridItem>
        </Grid>
        <Box mt="2">
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("invoiceDetails.name")}</Text>
          <Input
            autoFocus={createInvoice}
            placeholder={t("invoiceDetails.typeHere")}
            value={editInvoice.shop}
            disabled={!invoiceTypeNextStepAllowed}
            onChange={evt => onChangeTextOpenInvoice(`shop`, evt.target.value)}
            isInvalid={editInvoiceErrorArray.includes("shop")}
          />
        </Box> 
        <SelectInvoiceType 
          changeValue={onChangeTextOpenInvoice}
          value={editInvoice.invoiceType}
          disabled={(editInvoice.invoiceType != undefined && editInvoice.invoiceType != "" && createInvoice == false)}
          isInvalid={editInvoiceErrorArray.includes("invoiceType")}
          invoiceTypesData={invoiceTypesData}
          ></SelectInvoiceType>

        <SelectBudget 
          changeValue={onChangeTextOpenInvoice}
          disabled={!invoiceTypeNextStepAllowed}
          value={editInvoice.category}
          categoriesData={categoriesData}
          isInvalid={editInvoiceErrorArray.includes("category")}
          session={session}
          api_url={api_url}
          invoiceDate={editInvoice.date} />
        
        <Grid templateColumns='repeat(2, 1fr)' gap={3}>
          <GridItem>
            <Box mt="4">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("invoiceDetails.date")}</Text>
              {invoiceTypeNextStepAllowed &&
                <DatePicker
                  renderInput={renderCustomInput} 
                  value={selectedDay}
                  onChange={changeInvoiceDate}
                  colorPrimary="#F39F50" 
                ></DatePicker>
              }
              {!invoiceTypeNextStepAllowed &&
                <Input
                placeholder={t("invoiceDetails.typeHere")}
                value={selectedDay.day+"."+selectedDay.month+"."+selectedDay.year}
                disabled={!invoiceTypeNextStepAllowed}
                />
              }
            </Box>
          </GridItem>
          <SelectCurrency value={editInvoice.currency} disabled={!invoiceTypeNextStepAllowed} changeValue={onChangeTextOpenInvoice} />
        </Grid>
        
        {/*(session != undefined && (session["user_data"]["role"] == "ADMIN" || session["rights"].includes("manage-invoice-owner"))) &&
        <Box mt="4">
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Creator</Text>
          <Select
            disabled={!invoiceTypeNextStepAllowed}
            value={editInvoice.author}
            onChange={(e) =>{ onChangeTextOpenInvoice(`author`, e.target.value)}}
          >
            <option value="" disabled selected>Please select</option>
            {userData.map((singleUser)=>{
              return(
                <option key={singleUser._id+"-list-user-inv"} value={singleUser._id}>{singleUser.username}</option>
              )
            })}
          </Select>
        </Box>
          */}
        {"travelExpense" in editInvoice &&
          <TravelExpensesElement changeTravelExpenseLocation={changeTravelExpenseLocation} invoiceData={editInvoice} updateMeals={updateMeals} invoiceTypeNextStepAllowed={invoiceTypeNextStepAllowed} editInvoiceErrorArray={editInvoiceErrorArray} countryListAllowance={countryListAllowance} travelExpense={editInvoice.travelExpense} readOnlyAdminFields={false} changeText={onChangeTextTravelExpense}></TravelExpensesElement>
        }
        {editInvoice.invoiceKind != "travel-expense" &&
        <Divider mt="5"></Divider>
        }
        <Grid mt="5" mb="4" templateColumns='repeat(8, 1fr)' gap={3}>
          <GridItem colSpan={6}>
            { editInvoice.invoiceKind != "travel-expense" &&
            <Heading as="h3" size={"md"}>
                {t("invoiceDetails.totalAmount")}             
            </Heading>
            }
            {/* editInvoice.invoiceKind == "travel-expense" &&
            <Heading as="h3" size={"md"}>
                Your Compensation              
            </Heading>
            */}
          </GridItem>
          <GridItem colSpan={2}>
          </GridItem>
        </Grid>
        {(editInvoice.taxPositions == undefined || editInvoice.taxPositions.length == 0) &&
        <Box mt="2">
          <Grid templateColumns='repeat(2, 1fr)' gap={3}>
            <GridItem colSpan={1} >
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("invoiceDetails.amountIn")}{editInvoice.currency}</Text>
              <Input
                placeholder='0'
                value={editInvoice.total}
                onChange={evt => onChangeTotalOpenInvoice(`value`, evt.target.value, 0)}
                type="number"
                min="0"
                disabled={!invoiceTypeNextStepAllowed}
                isInvalid={editInvoiceErrorArray.includes("amount")}
              />
            </GridItem>    
            <GridItem colSpan={1} >
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("invoiceDetails.vatIn")}</Text>
              <Input
                placeholder={t("invoiceDetails.typeHere")}
                value={editInvoice.mwst}
                onChange={evt => onChangeTotalOpenInvoice(`taxRate`, evt.target.value, 0)}
                type="number"
                min="0"
                max="100"
                disabled={!invoiceTypeNextStepAllowed}
              />
            </GridItem> 
          </Grid>
        </Box>
        }
        {(editInvoice.taxPositions != undefined  && editInvoice.taxPositions.length != 0)  && editInvoice.invoiceKind != "travel-expense" &&
        <Box>
          {editInvoice.taxPositions.map((singleTaxPosition, index)=>{
            let addRemoveButton = false;
            if(index > 0){
              addRemoveButton = true;
            }

            return(
            <Grid key={index+"-tax"} mt="2" templateColumns='repeat(16, 1fr)' gap={3}>
              <GridItem colSpan={5} >
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("invoiceDetails.grossAmount")}</Text>
                  <CurrencyInput
                    className={"like-chakra-input" + (editInvoiceErrorArray.includes("amount") ? " invalid" : "")}
                    placeholder={t("invoiceDetails.enterNumber")}
                    defaultValue={singleTaxPosition.value}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    intlConfig={currencyInputConfig}
                    step={1}
                    onValueChange={(value, name, values) => onChangeTotalOpenInvoice(`value`, values.float, index)}
                  />
              </GridItem>    
              <GridItem colSpan={5} >
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("invoiceDetails.netAmount")}</Text>
                  <CurrencyInput
                    className={"like-chakra-input" + (editInvoiceErrorArray.includes("amountNet") ? " invalid" : "")}
                    placeholder={t("invoiceDetails.enterNumber")}
                    defaultValue={singleTaxPosition.valueNet}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    intlConfig={currencyInputConfig}
                    step={1}
                    onValueChange={(value, name, values) => onChangeTotalOpenInvoice(`valueNet`, values.float, index)}
                    disabled={(!invoiceTypeNextStepAllowed || editInvoice.invoiceKind == "travel-expense")}
                  />
              </GridItem>    
              <GridItem colSpan={2} >
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("invoiceDetails.vatIn")}</Text>
                  <CurrencyInput
                    className={"like-chakra-input" + (editInvoiceErrorArray.includes("taxRate") ? " invalid" : "")}
                    placeholder={t("invoiceDetails.enterNumber")}
                    defaultValue={singleTaxPosition.taxRate}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    intlConfig={currencyInputConfig}
                    step={1}
                    maxLength={2}
                    onValueChange={(value, name, values) => onChangeTotalOpenInvoice(`taxRate`, values.float, index)}
                    disabled={(!invoiceTypeNextStepAllowed || editInvoice.invoiceKind == "travel-expense")}
                    suffix={" %"}
                  />
              </GridItem> 

              {(addRemoveButton == false && invoiceTypeNextStepAllowed == true) && (editInvoice.invoiceKind != "travel-expense")  &&
              <GridItem colSpan={4} >
                <Button width="full" mt="6" float="right" onClick={editInvoiceAddTaxRate}>
                  <IoAddCircle fontSize={"20px"} style={{marginRight: "5px"}}/>
                  {t("invoiceDetails.taxRates")}
                </Button>
              </GridItem>
              }
              {addRemoveButton && invoiceTypeNextStepAllowed == true &&
              <GridItem colSpan={4} >
                <Button width="full" mt="6" float="right" onClick={e => editInvoiceRemoveTaxRate(index)}>
                  <IoRemoveCircle fontSize={"20px"} style={{marginRight: "5px"}}/>
                  {t("invoiceDetails.remove")}
                </Button>
              </GridItem>
              }
            </Grid>
            )
          })}
        </Box>
        }
        

        {editInvoice.invoiceKind != "travel-expense" &&
        <Divider mt="6"></Divider>
        }
        {editInvoice.invoiceKind == "invoice" &&
        <Grid mt="6" mb="2" templateColumns='repeat(8, 1fr)' gap={3}>
            <GridItem colSpan={6}>
              <Heading as="h3" size={"md"}>
              {t("invoiceDetails.invoiceScan")}
              </Heading>
            </GridItem>
            <GridItem colSpan={2}>
              {invoiceTypeNextStepAllowed == true &&
              <Button size={"sm"} isLoading={uploadInProgress} float="right" onClick={() => fileInputRef.current && fileInputRef.current.click()} >
                <IoCloudUpload fontSize={"20px"} style={{marginRight: "5px"}}/>
                {t("invoiceDetails.uploadInvoice")}
              </Button>
              }
              <input ref={fileInputRef} style={{display: "none"}} type="file" id="file-upload" onChange={(e) => uploadFile(e.target.files[0], false, true)}/>
            </GridItem>
          </Grid>
          }
          {editInvoice.scanAttached != "" &&
          <Box 
            borderWidth={1}
            borderRadius='lg' 
            _hover={{
              cursor: "pointer",
              backgroundColor: "#f4f4f4"
            }}
            style={{transition: "all 0.2s"}} 
            >
              <Flex>
              <IoDocumentAttachOutline style={{fontSize: "2em", marginLeft:"2%", marginTop:"6px"}}></IoDocumentAttachOutline>
              <Spacer />
              <Box mt="2" mr="2" mb="2" float={"right"}>
                <Button  onClick={()=>removeImageFromInvoice()} mr="4" variant="ghost" colorScheme={"grey"} size={"sm"}>
                  <IoTrash fontSize={"20px"} style={{marginRight: "5px"}}/>
                  {t("invoiceDetails.delete")}
                </Button>
                <Button size={"sm"} onClick={()=>window.open(editInvoice.scanAttached, '_blank')}>
                  <IoOpen fontSize={"20px"} style={{marginRight: "5px"}}/>
                  {t("invoiceDetails.preview")}
                </Button>
              </Box>
              </Flex>
          </Box>
        }
        {editInvoice.scanAttached == "" && editInvoice.invoiceKind == "invoice" &&
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>{t("invoiceDetails.noDocument")}</Text>
        }
        {createInvoice == false &&
          <ChangeLogElement userData={userData} value={editInvoice.changelog}></ChangeLogElement>
        }

      </DrawerBody>

      <DrawerFooter style={{borderTop: "1px solid #e2e8f0"}}>
        <SimpleGrid  width={"100%"} columns={1}>
        <InvoiceStatusElement typeName="Invoice" createInvoice={createInvoice} nextStepAllowed={invoiceTypeNextStepAllowed} invoiceTypesData={invoiceTypesData} invoiceType={editInvoice.invoiceType} invoiceStatus={editInvoice.invoiceStatus} changeValue={editInvoiceStatusUpdate}></InvoiceStatusElement>
        <Flex mt={5}>
        {createInvoice != true &&
        <Button mr={3} onClick={previewBackwards}>
          <IoChevronBack/>
        </Button>
        }
        {createInvoice != true &&
        <Button mr={3} onClick={previewForwards}>
          <IoChevronForwardOutline/>
        </Button>
        }
        {createInvoice != true && editInvoice.invoiceKind == "travel-expense" &&
          <Button mr="3" variant='outline' onClick={()=>printTravelExpense(editInvoice._id)}>
              <IoPrint/>
              <Text ml="2">
              {t("invoiceDetails.print")}
              </Text>
          </Button>
        }
        <Spacer></Spacer>
        <Button mr={3} onClick={closeInvoice}>
        {t("invoiceDetails.cancelClose")}
        </Button>
        {(createInvoice == false && invoiceTypeNextStepAllowed == true) &&
        <Button onClick={saveInvoice} isLoading={saveInvoiceLoading} disabled={!editInvoiceModified} variant='outline'>
          <IoSave></IoSave>
          <Text ml="2">
          {t("invoiceDetails.saveChanges")}
          </Text>
        </Button>
        }
        {createInvoice == true &&
        <Button onClick={saveInvoice} isLoading={saveInvoiceLoading} variant='outline'>
          <IoSave></IoSave>
          {editInvoice.invoiceKind == "travel-expense" &&
            <Text ml="2">
              {t("invoiceDetails.addTravelExpense")}
            </Text>
          }
          {editInvoice.invoiceKind == "invoice" &&
            <Text ml="2">
              {t("invoiceDetails.addInvoice")}
            </Text>
          }
        </Button>
        }
        </Flex>
        </SimpleGrid>
      </DrawerFooter>
    </DrawerContent>
    {invoiceIsOpen && editInvoice.scanAttached != undefined && editInvoice.scanAttached != "" && editInvoice.scanAttached.split('.').pop() == "pdf" &&
    <Iframe scrolling='yes' className="pdf-viewer" url={editInvoice.scanAttached}/>
    } 
    {invoiceIsOpen && editInvoice.scanAttached != undefined && editInvoice.scanAttached != "" && editInvoice.scanAttached.split('.').pop() != "pdf" &&
    <div className="pdf-viewer image" style={{backgroundImage: "url("+editInvoice.scanAttached+")"}}/>
    } 
    {invoiceIsOpen && editInvoice.invoiceKind != "travelExpense" &&
    <div className="pdf-viewer image map">

    </div>
    }  
  </Drawer>


      
    <Modal isOpen={isCloseEditWithoutSave} onClose={onCloseEditWithoutSaveClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>{t("invoiceDetails.closeWithoutSaving")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{t("invoiceDetails.sureToClose")}</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={()=>{onCloseEditWithoutSaveClose(); closeInvoice()}} mr={3}>{t("invoiceDetails.cancelClose")}</Button>
            <Button onClick={()=>{saveInvoice(); onCloseEditWithoutSaveClose();}} mr={3}>
              <IoCheckmarkDoneCircleSharp style={{fontSize: "1.2em", marginRight:"5px"}}></IoCheckmarkDoneCircleSharp>
              {t("invoiceDetails.saveAndClose")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

  </div>
  ) : null;
  
  return modalContent
};

export default InvoiceDetailDrawer;