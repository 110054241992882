import {
    Link as ChakraLink,
    Text,
    GridItem,
    Accordion,
    AccordionItem,
    AccordionButton,
    Heading,
    Spacer,
    AccordionPanel,
    AccordionIcon,
    Flex,
    Badge,
    Box
} from '@chakra-ui/react'

import { useTranslation } from 'next-i18next'
import React from "react";


const ChangeLogElement = ({value, userData}) => {
  const {t} = useTranslation('common')
  return(
    <Accordion mt="8" pb={10} borderBottom={"0px solid white"}  allowMultiple >
            <AccordionItem>
                <AccordionButton className={"without-highligt"} pt={4} pb={4} paddingLeft={0}>
                  <Heading as="h3" size={"md"}>
                    {t("changeLogelement.changeHistory")}
                  </Heading>
                  <Spacer/>
                  <AccordionIcon />
                </AccordionButton>
              <AccordionPanel pb={4} pl={0} pr={0}>
                {value != "" &&
                <div>

                {value.sort((a,b)=> b.date - a.date).map((changelogElement)=>{
                  return(
                    <Box 
                    key={changelogElement._id+"-change"}
                    borderWidth={1}
                    borderRadius='lg' mt="2" p="3">
                    {changelogElement.updates.map((singleUpdate)=>{
                
                      if(singleUpdate.field == "created"){
                        return(
                          <Flex key={singleUpdate._id+"update"} mt="">
                            <GridItem mr="2">
                              <Badge colorScheme="gray">
                                {singleUpdate.field}
                              </Badge>
                            </GridItem>
                            <GridItem mr="2">
                              <Text>{t("changeLogelement.thisInvoice")}</Text>
                            </GridItem>
                          </Flex>
                        )
                      }
                  
                      return(
                          <Flex key={singleUpdate._id+"update"} mt="">
                            <GridItem mr="2">
                              <Text>
                                {t("changeLogelement.changed")}
                              </Text>
                            </GridItem>
                            <GridItem mr="2">
                              <Badge colorScheme="gray">
                                {singleUpdate.field}
                              </Badge>
                            </GridItem>
                            <GridItem mr="2">
                              <Text>{t("changeLogelement.from")}</Text>
                            </GridItem>
                            <GridItem mr="2">
                              <Badge colorScheme="gray">
                                {singleUpdate.oldValue}
                              </Badge>
                            </GridItem>
                            <GridItem mr="2">
                              <Text>{t("changeLogelement.to")}</Text>
                            </GridItem>
                            <GridItem mr="2">
                              <Badge colorScheme="gray">
                                {singleUpdate.newValue}
                              </Badge>
                            </GridItem>
                          </Flex>
                      )
                    })}
                    <Flex mt="2" gap={2}>
                      {userData.some(singleUserData => singleUserData._id === changelogElement.user)&&
                        <Text as="b">{userData.find(obj => obj._id === changelogElement.user).username}</Text>
                      }          
                      <Text as="i" fontSize={"sm"} mt="0.5" color={"gray.500"}>
                        {new Date(changelogElement.date).toLocaleDateString()} {new Date(changelogElement.date).toLocaleTimeString()}
                      </Text>
                    </Flex>
                    </Box>
                  )
                })}
                </div>
                }
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
  )
};

export default ChangeLogElement;